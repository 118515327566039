import './Footer.scss';

import foot from './foot.svg';
import logo from '../../assets/icons/logo/logo.svg';

import linkedin from '../../assets/icons/light/linkedin.svg';
import twitter from '../../assets/icons/light/twitter.svg';
import insta from '../../assets/icons/light/insta.svg';
import git from '../../assets/icons/light/git.svg';
import { BrowserRouter, Link as NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import toparrow from '../../assets/icons/light/toparrow.svg';
import { Link } from 'react-scroll';

export default function Footer(props) {

    const lang = props.data.translation.footer;

    const rec = require('../../assets/data/info.json');

    return (
        <div className='Footer bg-app' id='footer'>
            <div className='f-container'>
                {/* <div className='curve'>
                    <Link to={"home"} className="topArrow" smooth={true} duration={500}>
                        <img src={toparrow} alt='Top arrow' />
                    </Link>
                </div> */}
                <div className='foot-wrap'>
                    <div className='row logo'>
                        <img src={logo} alt='Logo' />
                    </div>
                    <div className='row follow'>
                        {lang.follow}
                    </div>
                    <div className='row social'>
                        <NavLink to={rec.info.linkedin} target='_blank'><img src={linkedin} alt='Linkedin' /></NavLink>
                        <NavLink to={rec.info.twitter} target='_blank'><img src={twitter} alt='Twitter' /></NavLink>
                        <NavLink to={rec.info.insta} target='_blank'><img src={insta} alt='Instagram' /></NavLink>
                        <NavLink to={rec.info.git} target='_blank'><img src={git} alt='Github' /></NavLink>

                    </div>
                    <div className='row copyright'>
                        <small>{lang.copyright}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}
