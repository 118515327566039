
import location from '../../assets/icons/light/location.svg';
import call from '../../assets/icons/light/call.svg';
import mail from '../../assets/icons/light/mail.svg';
import www from '../../assets/icons/light/www.svg';

import './Contact.scss';
import { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

import bgcontact from '../../assets/img/contact.png';
import bgcontact_1 from '../../assets/img/contact_1.png';
import loader from '../../assets/icons/light/loader.svg';

export default function Contact({ data }) {

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [messege, setMessege] = useState('');
    const [success, setSuccess] = useState('');
    const [isSending, setSending] = useState(false);
    const [background, setBackground] = useState(0);

    const lang = data.translation;

    const rec = require('../../assets/data/info.json');

    function getTemplate(form) {
        const templateParams = {
            "fname": form[0].value,
            "lname": form[1].value,
            "email": form[2].value,
            "message": form[3].value
        }
        return templateParams;
    }


    const sendEmail = (e) => {
        setSending(true);
        e.preventDefault();
        console.log(getTemplate(e.target));
        emailjs.send('service_qkqiop9', 'template_egunr9p', getTemplate(e.target), 'ihO1q38-DIyH5MRpz')
            .then((result) => {
                setSuccess(lang.contact.success);
                resetFrom(e);
                setSending(false);
            }, (error) => {
                console.log(error.text);
                setSending(false);
            });

    };

    const resetFrom = (e) => {
        setFname('');
        setLname('');
        setEmail('');
        setMessege('');
    }

    useEffect(() => {
        // setTimeout(() => {
        //     console.log("Tri")
        //     var elements = document.querySelectorAll(".contact-form");
        //     elements.forEach((element) => {
        //         element.classList.toggle("collapse");
        //     })
        // }, 3000);
    });

    return (
        <div className="Contact bg-app" id="contact">
            <div className="c-cont padding">
                <div className="label">{lang.nav.contact}</div>
                <div className="social">
                    <div className="s-wrap">
                        <img src={location} alt='Location' />
                        <span className='info'>{rec.info.location}</span>
                    </div>
                    <div className="s-wrap">
                        <img src={call} alt='Call' />
                        <span className='info'>{rec.info.call}</span>
                    </div>
                    <div className="s-wrap">
                        <img src={mail} alt='Mail' />
                        <span className='info'>{rec.info.mail}</span>
                    </div>
                    <div className="s-wrap">
                        <img src={www} alt='WWW' />
                        <span className='info'>{rec.info.www}</span>
                    </div>
                </div>
                <div className="form-wrap">
                    <div className="left">
                        <form onSubmit={sendEmail}>
                            <input type='text' required value={fname} onChange={(e) => setFname(e.target.value)} placeholder={lang.contact.fname} />
                            <input type='text' required value={lname} onChange={(e) => setLname(e.target.value)} placeholder={lang.contact.lname} />
                            <input type='email' required value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang.contact.email} />
                            <textarea required value={messege} onChange={(e) => setMessege(e.target.value)} />
                            <input type="submit" required value={lang.contact.send} name={lang.contact.send} />
                        </form>
                        <span className='success-messege'>{success}</span>
                    </div>
                    <div className="right">
                        <img src={bgcontact} alt='Contact background' className='contact-form' />
                        <img src={bgcontact_1} alt='Contact background' className='contact-form collapse' />
                    </div>
                </div>
                {isSending ? <img id="loader" src={loader} alt='Loader' /> : null}
            </div>
        </div>
    )
}
