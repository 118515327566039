import { Link } from 'react-scroll';
import './TopNavigation.scss';

import home from '../../assets/icons/light/homenav.svg';
import about from '../../assets/icons/light/aboutmenav.svg';
import contact from '../../assets/icons/light/contactnav.svg';
import work from '../../assets/icons/light/worknav.svg';
import skills from '../../assets/icons/light/skills.svg';

export default function Navigation({ nav, top }) {

  return (
    <nav>
      <Link to="home" activeClass="active" spy={true} smooth={true} duration={1000}>{top ? nav.home : <img src={home} alt='Home' />}</Link>
      <Link to="about" activeClass="active" spy={true} smooth={true} duration={1000}>{top ? nav.about : <img src={about} alt='About' />}</Link>
      <Link to="skills" activeClass="active" spy={true} smooth={true} duration={1000}>{top ? nav.skills : <img src={skills} alt='Skills' />}</Link>
      <Link to="work" activeClass="active" spy={true} smooth={true} duration={1000}>{top ? nav.work : <img src={work} alt='Work' />}</Link>
      <Link to="contact" activeClass="active" spy={true} smooth={true} duration={1000}>{top ? nav.contact : <img src={contact} alt='Contact' />}</Link>
    </nav>
  )
}
