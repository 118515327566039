import React from 'react'
import './Work.scss';
import cross from '../../assets/icons/light/cross.svg';
import { Link } from 'react-scroll';



export default function WorkDetails({ closeModal, data }) {

    // const b_arrow = require('../../assets/icons/light/back_arrow.svg');

    // const navigate = useNavigate()

    const closeWorkInfoModal = () => {
        var body = document.getElementsByTagName("body");
        body[0].style.overflow = "unset";
        closeModal();
    }

    return (
        <div className='bg-overlay'>
            {
                <div id="WorkExperience" className='exp-info-cont'>
                    <div className='nav' ><img src={cross} alt='back arrow' onClick={() => closeWorkInfoModal()} /></div>
                    <div className='wrapper'>
                        <div className='l1'>{data.title.split(",")[0]}</div>
                        <div className='l2'>{data.company}</div>
                        <div className='l3'>{data.title.split(",")[1]}</div>
                        <section className='content'>
                            <label>Responsibilities</label>
                            <ul>
                                {
                                    data?.responsibility?.map((val, k) => {
                                        return <li>{val}</li>
                                    })
                                }
                            </ul>
                        </section>
                    </div>
                </div>
            }
        </div>
    )
}
