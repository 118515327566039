import './About.scss';

import headshot from '../../assets/resume/headshot.jpeg';
import { useEffect } from "react";
import Navigation from "../../components/TopNavigation/TopNavigation";
import download from '../../assets/icons/light/download.svg';
import Resume from '../../assets/resume/Resume.pdf';
import { BrowserRouter, NavLink } from 'react-router-dom';

import linkedin from '../../assets/icons/light/linkedin.svg';
import git from '../../assets/icons/light/git.svg';

export default function About({ data }) {

    const lang = data.translation;

    const rec = require('../../assets/data/info.json');

    const getY = (element) => {
        return element.getBoundingClientRect().y;
    }

    document.addEventListener('scroll', function () {
        var aboutNav = document.querySelector(".ab-nav");
        var homeNav = document.querySelector(".nav-home");
        if (aboutNav && homeNav && window.innerWidth > 600 && aboutNav !== undefined && homeNav !== undefined) {
            if (getY(aboutNav) < -60) {
                homeNav.style.height = "5rem";
            } else {
                homeNav.style.height = "0";
            }
        }
    })


    useEffect(() => {

    });

    return (
        <div className='About bg-app' id='about'>
            <div className="ab-nav">
                <Navigation nav={lang.nav} top={true} />
            </div>
            <div className="wrapper padding">
                <div className="abt-main">
                    <div className="label">{lang.nav.about}</div>
                    <section className="ab-info-wrap">
                        <div className="ab-l">
                            <section className="exp">
                                <span className="info">{lang.about.greeting}</span>
                                <span className="info">{lang.about.present}</span>
                                <span className="info">
                                    {lang.about.intro}
                                </span>

                            </section>
                        </div>
                        <div className="ab-r">
                            <div className="headshot">
                                <img src={headshot} alt="Headshot" />
                            </div>
                            <div className='social'>
                                <NavLink to={rec.info.linkedin} target='_blank'><img src={linkedin} alt='Linkedin' /></NavLink>
                                <NavLink to={rec.info.git} target='_blank'><img src={git} alt='Github' /></NavLink>
                            </div>
                        </div>
                    </section>
                    {/* <a className="down-wrap" href={Resume} download="Resume_SauravModi" title={lang.about.title_download}>
                        <img src={download} alt="Download url" />
                    </a> */}
                </div>
            </div>
        </div>
    )
}
