import { useEffect, useState } from 'react';
import './App.scss';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Work from './pages/work/Work';
import Contact from './pages/contact/Contact';
import Footer from './pages/footer/Footer';
import Logo from './components/Logo/Logo';
import Skills from './pages/skills/Skills';

function App() {

  const [light, setLightTheme] = useState(true);
  const [lang, setLanguage] = useState('EN');
  const [translation, setTranslation] = useState(require(`./assets/data/${lang}.json`));
  const [info, setInfo] = useState(require(`./assets/data/info.json`));
  const [loader, setLoader] = useState(false);

  const getICONURL = () => {
    var theme = light ? "light" : "dark";
    return `../../assetsicons/${theme}/`;
  }


  const data = {
    "lang": lang,
    "translation": translation,
    "light": light,
    "iconurl": getICONURL(),
    "info": info
  }

  const language = (value) => {
    setLanguage(value);
  }

  useEffect(() => {
    setTranslation(require(`./assets/data/${lang}.json`));

    setTimeout(() => {
      setLoader(false);
    }, 2300)
  }, [lang]);


  return (
    <div className="App">
      <div className='loader-container' style={{ display: loader ? 'grid' : 'none' }}>
        <Logo loader={true} />
      </div>
      <div style={{ display: !loader ? 'grid' : 'none' }} >
        <main>
          <Home data={data} language={language} />
          <About data={data} />
          <Skills data={data} />
          <Work data={data} />
          <Contact data={data} />
          <Footer data={data} />
        </main>
      </div>
    </div>

  );
}

export default App;
