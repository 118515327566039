
import './Logo.scss';

export default function Logo(props) {


    return (
        <svg width="636" height="640" viewBox="0 0 636 640" fill="none" xmlns="http://www.w3.org/2000/svg">

            <defs style={{ display: props.loader ? 'block' : 'none' }}>
                <linearGradient id="left-to-right">
                    <stop offset="0" stopColor="#03C4A1">
                        <animate dur="2s" attributeName="offset" fill="freeze" from="0" to="1.1" />
                    </stop>
                    <stop offset="0" stopColor="#0000">
                        <animate dur="2s" attributeName="offset" fill="freeze" from="0" to="1.1" />
                    </stop>
                </linearGradient>
            </defs>

            <path fill={!props.loader ? "#03C4A1" : "url(#left-to-right)"} stroke="#03C4A1" strokeWidth="1" d="M318.068 128L518 245.864L517.867 285.295L318.068 167.284L152.776 265.408L318.068 363.702L318.06 403L118 284.995L118.203 245.863L318.068 128Z" />
            <path fill={!props.loader ? "#03C4A1" : "url(#left-to-right)"} stroke="#03C4A1" strokeWidth="1" d="M318.03 520.198L118.044 402.525L118.02 363.315L318.005 480.942L482.763 382.705L318.004 284.869L318.005 245.651L518.007 363.309L518.054 402.507L318.03 520.198Z" />
            <path className={!props.loader ? "fill-path hover-animation" : "fill-path"} d="M18.001 170.005L318.076 19.9621L618.002 170.005L618.009 469.984L318.015 620.023L18.003 469.997L18.001 170.005Z" stroke="#03C4A1" strokeWidth="35" />
        </svg>



    )
}
