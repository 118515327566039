import React, { useEffect, useState } from 'react'
import './Skills.scss';

export default function Skills(props) {

    const [skills_list, setList] = useState(require('../../assets/data/skills.json'));

    const lang = props.data.translation;
    // var skills_list = require("../../assets/data/skills.json");
    const country = props.data.lang;

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    function shuffle() {
        setList(shuffleArray(skills_list));
        console.log(skills_list);
    }

    useEffect(() => {

    }, [skills_list]);

    return (
        <div className='Skills bg-app' id='skills'>

            <div className="s-cont">

                <div className="label">{lang.nav.skills}</div>
                <div className='skills-wrapper'>
                    {/* <button onClick={() => shuffle()}>Shuffle array</button> */}
                    {
                        skills_list.map((skill, i) => {
                            return <span key={i} className='skills-label'>{skill}</span>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
