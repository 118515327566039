import { Link } from "react-scroll";
import "./Home.scss";
import arrowi from '../../assets/icons/light/arrow.svg';
import logo from '../../assets/icons/logo/logo.svg';
import ReactDropdown from "react-dropdown";
import { useEffect, useState } from "react";
import Navigation from "../../components/TopNavigation/TopNavigation";
import hex from '../../assets/img/hexbg.svg';
import Logo from "../../components/Logo/Logo";
import { BrowserRouter, Link as NavLink } from "react-router-dom";
import { Button } from "bootstrap";
import menuArrow from '../../assets/icons/light/menuArrow.svg';

export default function Home({ data, language }) {

    const [lang, setLanguage] = useState("EN");
    const [topMenu, setTopMenu] = useState(true);

    const tr = data.translation;

    const options = [
        'EN', 'ES'
    ];

    document.addEventListener('scroll', () => {
        const element = document.querySelector(".scroller-wrapper");
        if (element !== null) {
            if (window.scrollY < 100) {
                const opac = 1 - ((window.scrollY / 100));
                element.style.transform = `translateY(-${window.scrollY}px)`;
                element.style.opacity = `${opac}`;
            } else {
                element.style.transform = `translateY(-100px)`;
                element.style.opacity = 0;
            }
        }

    })

    const toggleMenu = () => {
        const element = document.querySelector(".nav-home");
        element.classList.toggle("toggle-menu");
    }

    const broadcastLanguage = (val) => {
        setLanguage(val);
        language(val);
    }

    useEffect(() => {
        if (window.screen.width < 800)
            setTopMenu(false);
    }, [topMenu]);

    return (
        <div className="Home bg-app" id="home">
            <div className="nav-home">
                <div className="nav-wrap">
                    <Navigation nav={tr.nav} top={topMenu} />
                    <div className="nav-btn" onClick={() => toggleMenu()}>
                        <svg className="menu" width="650" height="350" viewBox="0 0 650 350" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 25H625M125 125H625M225 225H625M325 325H625" stroke="#03C4A1" strokeWidth="50" strokeLinecap="round" />
                        </svg>
                        <svg className="cross" width="550" height="450" viewBox="0 0 550 450" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 425L525 25M25 25L525 425" stroke="#03C4A1" strokeWidth="50" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="wrapper padding">
                <div className="head">
                    <NavLink to="#" ><Logo loader={false} /></NavLink>
                    <ReactDropdown className={"drop-root"}
                        onChange={(e) => broadcastLanguage(e.value)}
                        arrowClosed={<span className="arrow-closed">Test</span>}
                        menuClassName={"drop-menu"}
                        options={options} value={lang} placeholder="Select an option" />
                </div>
                <div className="main">

                    <div className="hex-container"><img src={hex} alt="Hex" /></div>
                    <div className="hex-container bottom"><img src={hex} alt="Hex bottom" /></div>

                    <section className="hide-overflow">
                        <div className="intro-wrap">
                            <div className="h-l1">{tr.home.hello} <span>{tr.home.name}</span></div>
                            <div className="h-l2">{tr.home.l2}</div>
                        </div>
                    </section>
                    <section className="hide-overflow-btn">
                        <Link className="h-l3" to="contact" smooth={true} duration={500}>
                            <div >{tr.home["btn-hire-me"]}</div>
                            <img src={arrowi} alt="Arrow" />
                        </Link>
                    </section>
                </div>
                <div className="foot">
                    <Link to="about" smooth={true} duration={500} className="scroller-wrapper">
                        <span className="scroller"></span>
                    </Link>
                </div>
            </div>
        </div>

    )
}