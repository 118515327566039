import { BrowserRouter, Link } from "react-router-dom";

import './Work.scss';
import backArrow from '../../assets/icons/light/back_arrow.svg';
import { useEffect, useState } from "react";
import WorkDetails from "./WorkDetails";

export default function Work(props) {

    const [workInfo, setInfo] = useState({});
    const [showWorkInfo, setWorkInfo] = useState(false);

    const lang = props.data.translation;
    const country = props.data.lang;

    const works = require(`../../assets/data/work_${props.data.lang}.json`);

    const showProjectInfo = (i) => {
        console.log("work info " + showWorkInfo);
        var body = document.getElementsByTagName("body");
        body[0].style.overflow = "hidden";
        //body.add.overflow = "hidden";
        // var element = document.querySelector(".work-section");
        // element.style.transform = "translateX(-100%)";
        setInfo(works[i]);
        setWorkInfo(() => true);

    }

    const closeModal = () => {

        setWorkInfo(() => false);
    }

    const hideProjectInfo = () => {
        var element = document.querySelector(".work-section");
        element.style.transform = "translateX(0%)";
    }

    useEffect(() => {

    })

    return (
        <div className='Work bg-app' id='work'>
            <div className="w-cont">
                <div className="label">{lang.nav.work}</div>
                <div className="fixed-wrapper">
                    <div className="work-section">
                        <section className="w-sub-cont">
                            {
                                works.map((work, i) => {
                                    return <div onClick={() => showProjectInfo(i)} className="work-wrapper">
                                        <span className="company">{work.company}</span>
                                        <span className="title">{work.title}</span>
                                        <span className="responsibility">{work.intro}</span>
                                        <span className="skills-used">
                                            {
                                                work?.techstack?.map((val, i) => {
                                                    return <span>{val}</span>
                                                })
                                            }
                                        </span>
                                    </div>
                                })
                            }
                        </section>
                    </div>
                </div>
            </div>
            {
                showWorkInfo ? <WorkDetails data={workInfo} closeModal={closeModal} /> : null
            }
        </div>
    )
}
